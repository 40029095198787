var $whCover = $('<div style="position:fixed;top:0;left:0;z-index:1000;width:100%;height:100%;background:#fff;"></div>').prependTo('body');

$(window).on('load', function (e) {
  var $headAnimEls = $('.js-headAnimEl');
  $headAnimEls.css({
    opacity: 0,
    transform: 'translateY(20px)'
  });
  var l = $headAnimEls.length;
  var i = 0;
  $whCover.fadeOut(1000, function () {
    var timer = setInterval(function () {
      $headAnimEls.eq(i).css({
        transition: '0.5s',
        opacity: 1,
        transform: 'translateY(0)'
      });
      i++;
      if (i == l) {
        clearInterval(timer);
      }
    }, 200);
  });
});
